<template>
  <div>
    <b-card>
      <validation-observer ref="form"
                           v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="onSubmit"
        >
          <div>
            <validation-provider
              v-slot="{ errors }"
              :name="$t('labels.password')"
              vid="password"
              rules="required"
            >
              <b-form-group
                :label="$t('labels.password')"
              >
                <b-form-input
                  v-model="form.password"
                  type="password"
                  :placeholder="$t('labels.password')"
                  autocomplete="new-password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>

          <div>
            <validation-provider
              v-slot="{ errors }"
              :name="$t('labels.new_password')"
              vid="new_password"
              rules="required"
            >
              <b-form-group
                :label="$t('labels.new_password')"
              >
                <b-form-input
                  v-model="form.new_password"
                  type="password"
                  :placeholder="$t('labels.new_password')"
                  autocomplete="new-password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>

          <div>
            <validation-provider
              v-slot="{ errors }"
              :name="$t('labels.confirm_password')"
              vid="new_password_confirmation"
              rules="required"
            >
              <b-form-group
                :label="$t('labels.confirm_password')"
              >
                <b-form-input
                  v-model="form.new_password_confirmation"
                  type="password"
                  :placeholder="$t('labels.confirm_password')"
                  autocomplete="new-password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <b-row>
            <b-col cols="12 text-right">
              <LoadingButton :button-text="$t('reset password')" />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      roles: [],
      image: null,
      form: {
        password: '',
        new_password: '',
        new_password_confirmation: '',
      },
    }
  },
  computed: {
    submitMethod() {
      return 'PUT'
    },
    toRedirectResource() {
      return 'dashboard-analytics'
    },
  },
}
</script>
