var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('labels.password'),"vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.password')}},[_c('b-form-input',{attrs:{"type":"password","placeholder":_vm.$t('labels.password'),"autocomplete":"new-password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('labels.new_password'),"vid":"new_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.new_password')}},[_c('b-form-input',{attrs:{"type":"password","placeholder":_vm.$t('labels.new_password'),"autocomplete":"new-password"},model:{value:(_vm.form.new_password),callback:function ($$v) {_vm.$set(_vm.form, "new_password", $$v)},expression:"form.new_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('labels.confirm_password'),"vid":"new_password_confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.confirm_password')}},[_c('b-form-input',{attrs:{"type":"password","placeholder":_vm.$t('labels.confirm_password'),"autocomplete":"new-password"},model:{value:(_vm.form.new_password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "new_password_confirmation", $$v)},expression:"form.new_password_confirmation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('LoadingButton',{attrs:{"button-text":_vm.$t('reset password')}}),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('labels.reset'))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }